@import 'configurable-values';

.lv-icon-button:disabled {
  background-color: transparent !important;
}

.mat-review-round-button {
  min-width: 272px !important;
  max-width: 325px !important;
  width: 90%;
  height: 40px !important;
  border-radius: 24px !important;
  background-color: $tint-primary;
  color: $nuetrals-white !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
  margin-bottom: 20px !important;
}

.mat-rounded-button {
  margin: 37px 0 16px !important;
  padding: 14px 133px 12px !important;
  border-radius: 24px !important;
  background-color: $tint-primary;
  color: $nuetrals-white !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-continue-round-button {
  width: 120px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background-color: $tint-primary;
  color: $nuetrals-white !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

button.mat-button.mat-button-disabled.mat-continue-round-button-disabled {
  width: 120px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background-color: $nuetrals-grey-disabled !important;
  color: $dark-grey !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-round-cancel-button {
  width: 78px;
  height: 40px;
  margin: 0 8px 0 0;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  border-radius: 20px !important;
  border: solid 2px $tux !important;
}

.mat-round-back-button {
  height: 48px;
  margin: 0 8px 0 0;
  padding: 10px 15px;
  font-family: AvenirNext;
  font-size: 0.938rem;
  font-weight: 600;
  line-height: 1.33;
  text-align: center;
  border-radius: 24px !important;
  border: solid 2px $tux !important;

  &-disabled {
    background-color: $nuetrals-grey-disabled !important;
    color: $dark-grey !important;
  }
}

.mat-activate-round-button {
  padding: 10px !important;
  border-radius: 24px !important;
  background-color: $old-pine;
  color: $nuetrals-white !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-pause-round-button {
  padding: 10px !important;
  border-radius: 24px !important;
  background-color: $pastel-red;
  color: $nuetrals-white !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-edit-round-button {
  padding: 10px !important;
  border-radius: 24px !important;
  background-color: $tint-primary;
  color: $nuetrals-white !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-archive-round-button {
  padding: 10px !important;
  border-radius: 24px !important;
  background-color: $dark-grey;
  color: $nuetrals-white !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-round-save-button {
  width: 69px;
  height: 40px;
  margin: 0 0 0 8px;
  padding: 10px 15px 8px 16px;
  border-radius: 24px !important;
  font-size: 17px;
  line-height: 22px;
  background-color: $tint-primary;
  color: $nuetrals-white !important;
}

.mat-round-save-button:disabled {
  background-color: $nuetrals-grey-disabled;
  color: #8e8e93;
}

.mat-round-more-button {
  min-width: 2.875rem !important;
  width: 2.875rem !important;
  height: 2.875rem !important;
  margin: 0 0.875rem 0.25rem !important;
  padding: 0.563rem !important;
  font-size: 17px !important;
  line-height: 22px !important;
  border-radius: 50% !important;
  background-color: $tint-primary;
  color: $nuetrals-white !important;
}

.mat-create-round-button {
  width: 132px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background-color: $tint-primary;
  color: $nuetrals-white !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-add-round-button {
  min-width: 132px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background-color: $tint-primary;
  color: $nuetrals-white !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-add-round-button:disabled {
  min-width: 132px !important;
  height: 48px !important;
  border-radius: 24px !important;
  background-color: $nuetrals-grey-disabled;
  color: #8e8e93;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-add-round-team {
  min-width: 132px !important;
  height: 40px !important;
  border-radius: 24px !important;
  background-color: $tint-primary;
  color: $nuetrals-white !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-add-round-team:disabled {
  min-width: 132px !important;
  height: 40px !important;
  border-radius: 24px !important;
  background-color: $nuetrals-grey-disabled;
  color: #8e8e93;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-create-add-button {
  margin-right: 10px !important;
}

.mat-illustration-button {
  width: 190px;
  height: 190px;
  border-radius: 16px !important;
  border: solid 4px $nuetrals-grey-5 !important;
}

.mat-illustration-button:hover {
  border: solid 4px $tux !important;
}

.mat-illustration-button-text {
  line-height: 16px !important;
  padding-top: 10px !important;
}

.mat-icon-button.large {
  width: 48px;
  height: 48px;
  line-height: 48px;

  .mat-icon {
    font-size: 48px;
    width: 48px;
    height: 48px;
    line-height: 48px;
  }
}

.mat-icon-button.medium {
  width: 24px;
  height: 24px;
  line-height: 24px;

  .mat-icon {
    font-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}

.mat-button.lv-answer-add-image-button {
  width: 99px;
  height: 24px;
  margin: 7.5px 0 1px 281px;
  padding: 4px 8px 3px 6px;
  border-radius: 4px;
  background-color: $tint-primary;
  color: $nuetrals-white;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: -0.08px;

  .mat-icon {
    margin-right: 2px;
    font-size: 16px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    color: $nuetrals-white;
  }
}

.mat-round-white-button {
  font-size: 17px;
  font-weight: 550;
  color: $nuetrals-white;
  padding: 5px 5px 4px 17px !important;
  border: solid 2px $nuetrals-white !important;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px !important;
}

.mat-red-round-button {
  padding: 10px !important;
  border-radius: 24px !important;
  background-color: $pastel-red;
  color: $nuetrals-black-pure !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-orange-round-button {
  padding: 10px !important;
  border-radius: 24px !important;
  background-color: $tint-primary;
  color: $nuetrals-black-pure !important;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29 !important;
  letter-spacing: normal;
  text-align: center !important;
}

.mat-button-disabled {
  background-color: transparent !important;
  color: $nuetrals-grey-4 !important;
}

.mat-illustration-button .png {
  max-width: 86px;
  max-height: 86px;
  height: auto;
}

@import '../global/configurable-values';

.cell-phone-outline {
  background-image: url('../../img/cellphone/cell-phone-outline.svg');
  background-repeat: no-repeat;
  width: 423px;
  height: 770px;
  padding: 52px 22px 51px 26px;
  object-fit: contain;
  margin-top: 10px;
}

.Rectangle-Copy {
  width: 375px;
  height: 670px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: $tux;
}

.BarsStatusWhite {
  object-fit: contain;
  width: auto;
  height: auto;
}

.TopIconContainer {
  margin: 0 16px;
}

.BarBackLeft {
  width: 28px;
  height: 28px;
  object-fit: contain;
}

.ShareIconRight {
  width: 28px;
  height: 28px;
  object-fit: contain;
}

.preview-image-container {
  width: 343px;
  height: 187px;
  background-color: $nuetrals-grey-5 !important;
  margin: 16px;
  border-radius: 5px;
}

.preview-image-icon {
  display: block;
  margin: auto auto;
  padding-top: 35px;
}

.preview-image-file {
  width: 343px;
  height: 187px;
  border-radius: 5px;
}

.preview-brand-name {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: $nuetrals-white;
  margin: 0 16px;
  overflow-y: auto;
  max-height: 30px;
}

.preview-product-name {
  font-size: 17px;
  line-height: 22px;
  color: $nuetrals-white;
  margin: 0 16px;
  overflow-y: auto;
  max-height: 25px;
}

.preview-license {
  font-size: 13px;
  line-height: 17px;
  color: $nuetrals-white;
  margin: 0px 16px;
  letter-spacing: -0.08px;
  overflow-y: auto;
  max-height: 25px;
}

.preview-product-description {
  font-size: 16px;
  line-height: 26px;
  color: $nuetrals-white;
  margin: 0px 16px;
  overflow-y: auto;
  overflow-style: unset;
  height: 150px;
}

.preview-quiz-product-description {
  margin: 0px 16px;
}

.preview-fine-print {
  margin-top: 10px;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: $nuetrals-white;
}

.preview-fine-print-description {
  font-size: 16px;
  line-height: 1.2;
  color: $nuetrals-white;
  min-height: 84px;
}

.preview-fine-print-item {
  display: flex;
  align-items: center;

  &::before {
    content: '-';
    display: block;
    margin-right: 5px;
  }
}

.preview-start-button {
  width: 343px;
  margin: 10px 16px 25px;
  height: 48px;
  border-radius: 24px;
  background-color: $tint-primary;
  color: $nuetrals-white;
  font-family: AvenirNext, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-icons-row {
  margin: 0px 16px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;
}

.offer-preview-tab-text {
  font-size: 12px;
  color: $dark-grey;
  line-height: 16px;
  text-align: center;
}

@import '../global/configurable-values';
@import '../global/mixins';

.lv-offer-filter-row {
  .mat-menu {
    background: $nuetrals-white;
    color: $nuetrals-black-pure;
  }
}

.lv-filter-offers {
  padding: 11px 16px 9px 11px;
  color: $nuetrals-white !important;
  cursor: auto;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  border: 2px solid $nuetrals-white;

  input[type='text'] {
    cursor: auto;
    color: $nuetrals-white;
    background: $tux;
    border: none;
    outline: none;
    font-family: AvenirNext;
    font-size: 1.063rem;
    font-weight: 600;
    line-height: 1.29;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $nuetrals-white;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $nuetrals-white;
  }
}

.lv-filter-offers-focus {
  padding: 11px 16px 9px 11px;
  color: $tux !important;
  background-color: $nuetrals-white !important;
  cursor: pointer;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  border: 2px solid $nuetrals-white;

  input[type='text'] {
    cursor: auto;
    background: $nuetrals-white;
    color: $tux;
    border: none;
    outline: none;
    font-size: 17px;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $tux;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $tux;
  }
}

.lv-filter-title {
  color: $nuetrals-black-pure;
  min-width: 250px;
}

.mat-menu-panel {
  -ms-overflow-style: none !important; /* IE and Edge */
  ::-webkit-scrollbar {
    display: none !important;
  }
}

.lv-nested-menu {
  background: $nuetrals-white !important;
  color: $tux !important;
  padding-right: 5px !important;

  .mat-menu-item {
    background: transparent;
    color: $tux !important;
  }

  .mat-menu-item-submenu-trigger::after {
    background: transparent;
    color: $tux !important;
  }

  form {
    color: $tux !important;
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
    ::-webkit-scrollbar {
      display: none !important;
    }
  }
}

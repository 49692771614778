body {
  font-family: AvenirNext, sans-serif !important;
}

// Import main Angular Material Theme
@import 'assets/scss/theme/leaf-connect.theme';

// Import Material Mixins
@import 'assets/scss/theme/mixins';

// Global
@import 'assets/scss/global/global';

// Components
@import 'assets/scss/components/components';

.mat-tooltip {
  font-size: 14px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.font-bold {
  font-weight: 600;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.capitalize {
  text-transform: capitalize;
}

.margin-right-5px {
  margin-right: 5px !important;
}

.margin-right-20px {
  margin-right: 20px !important;
}

.margin-top-5px {
  margin-top: 5px;
}

.margin-top-10px {
  margin-top: 10px;
}

.margin-bottom-5px {
  margin-bottom: 10px;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}

.margin-top-20px {
  margin-top: 20px;
}

.margin-top-30px {
  margin-top: 30px;
}

.margin-20px {
  margin: 20px;
}

.margin-y-5px {
  margin-top: 5px;
  margin-bottom: 5px;
}

.padding-bottom-10px {
  padding-bottom: 10px;
}

.padding-top-5px {
  padding-top: 5px;
}

.padding-10px {
  padding: 10px;
}

.padding-20px {
  padding: 20px;
}

.padding-top-10px {
  padding-top: 10px;
}

.padding-top-20px {
  padding-top: 20px;
}

.padding-top-30px {
  padding-top: 30px;
}

.padding-left-10px {
  padding-left: 5px;
}

.padding-left-5px {
  padding-left: 5px;
}

.padding-left-25px {
  padding-left: 25px;
}

.padding-right-5px {
  padding-right: 5px;
}

.padding-right-3rem {
  padding-right: 3rem;
}

.max-width-table {
  min-width: 100% !important;
  overflow-x: auto;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.full-width-height {
  height: 100% !important;
  width: 100% !important;
}

.full-width-table {
  width: 100%;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 1px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 1px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-styling {
  border: none !important;
  border-radius: 0px !important;
  padding: 0px;
  margin: 0px;
}
